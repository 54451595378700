import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import { Formik } from "formik"
import { RiSendPlane2Line } from "react-icons/ri"

import Layout from "../components/layout"
import SEO from "../components/seo"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const generic_error_msg =
  "There was some error. It is recommended to try later again."

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({ data }) => {
  const [generalMsg, setGeneralMsg] = useState("")
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout className="contact-page">
      <SEO
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <div className="wrapper">
        <h1>{frontmatter.title}</h1>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {generalMsg}
        <Formik
          initialValues={{
            name: "",
            email: "",
            message: "",
          }}
          onSubmit={async (values, formikBag) => {
            setGeneralMsg("Sending...")
            // console.log("values", values)
            let formData = new FormData()

            formData.append("name", values.name)
            formData.append("email", values.email)
            formData.append("message", values.message)
            // console.log("formData", formData)

            let res, res_json
            try {
              res = await fetch(
                "https://api.formium.io/submit/5f943075f0736d000186e5e7/foundation-demo-pws",
                {
                  method: "POST", //s
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(values),
                }
              )
              res_json = await res.json()
              if (res.status == 200) {
                setGeneralMsg("")
                navigate("/thanks")
                // return
              } else {
                setGeneralMsg(generic_error_msg)
                return
              }
            } catch (res_error) {
              setGeneralMsg(generic_error_msg)
              return
            }

            if (res_json.ok !== true) {
              // show validation or any process/operation fail error
              setGeneralMsg(generic_error_msg)
              return
            } else {
              setGeneralMsg("")
              navigate("/thanks")
            }
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props
            return (
              <form
                className="contact-form"
                name="contact"
                method="POST"
                onSubmit={handleSubmit}
              >
                <p>
                  <label>
                    Name
                    <input
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </label>
                </p>
                <p>
                  <label>
                    Email
                    <input
                      type="email"
                      name="email"
                      required
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </label>
                </p>
                <p>
                  <label>
                    Message
                    <textarea
                      name="message"
                      required
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </label>
                </p>
                <p className="text-align-right">
                  {generalMsg}
                  <button className="button" type="submit">
                    Send Message{" "}
                    <span className="icon -right">
                      <RiSendPlane2Line />
                    </span>
                  </button>
                </p>
              </form>
            )
          }}
        </Formik>
      </div>
    </Layout>
  )
}

export default Contact
